.app-body {
  position: relative;
  z-index: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  padding-bottom: calc(env(safe-area-inset-bottom) + 80px);

  .smartdeer-app-image {
    width: 295px;
    height: 49.5px;
    object-fit: cover;
    margin-bottom: 40px;
  }

  .hi-image{
    width: 100%;
    margin-bottom: 20px;
  }

  .welcome-image {
    width: 295px;
    height: 62.5px;
    object-fit: cover;
    margin-bottom: 40px;
  }

  .download-app-image {
    width: 295px;
    object-fit: cover;
    cursor: pointer;
  }
}
