.page-container {
  min-height: 100vh;
  background: #fff;
}

.page-main {
  width: 375px !important;
  max-width: 460px;
  min-height: 100vh;
  margin: 0 auto;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.07);
}