
.load {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: white;
  z-index: 10000000000;
  display: flex;
  align-items: center;
  justify-content: center;

  // animation-name:fadenum;
  // animation: fadenum 3s linear;
  // animation-iteration-count: 1;
  // // animation-play-state: fadenum;

  img {
    width: 50vw;
  }
}

@keyframes fadenum{

  0%{opacity:1;}

  50%{opacity:0.5;}

  99%{opacity:0.1;}

  100%{
    opacity:0;
    // display: none;
  }
}